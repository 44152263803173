/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
} */

.loading-height {
	height: 90vh;
}

.form-input {
	background-color: #fff;
	border-color: #d1d5db;
	border-width: 1px;
	border-radius: 0.25rem
		/* 4px */
	;
	padding-top: 0.5rem
		/* 8px */
	;
	padding-right: 0.75rem
		/* 12px */
	;
	padding-bottom: 0.5rem
		/* 8px */
	;
	padding-left: 0.75rem
		/* 12px */
	;
	font-size: 1rem
		/* 16px */
	;
	line-height: 1.5;
}

.form-input:disabled {
	background-color: #d1d5db;
	opacity: 100;
	border-color: #d1d5db;
	border-width: 1px;
	border-radius: 0.25rem
		/* 4px */
	;
	padding-top: 0.5rem
		/* 8px */
	;
	padding-right: 0.75rem
		/* 12px */
	;
	padding-bottom: 0.5rem
		/* 8px */
	;
	padding-left: 0.75rem
		/* 12px */
	;
	font-size: 1rem
		/* 16px */
	;
	line-height: 1.5;
}

.form-select {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
	appearance: none;
	color-adjust: exact;
	background-repeat: no-repeat;
	background-color: #fff;
	border-color: #e2e8f0;
	border-width: 1px;
	border-radius: 0.25rem
		/* 4px */
	;
	padding-top: 0.5rem
		/* 8px */
	;
	padding-right: 2.5rem
		/* 40px */
	;
	padding-bottom: 0.5rem
		/* 8px */
	;
	padding-left: 0.75rem
		/* 12px */
	;
	font-size: 1rem
		/* 16px */
	;
	line-height: 1.5;
	background-position: right 0.5rem center;
	background-size: 1.5em 1.5em;
}

.form-select::-ms-expand {
	color: #a0aec0;
	border: none;
}

@media not print {
	.form-select::-ms-expand {
		display: none;
	}
}

@media print and (-ms-high-contrast: active),
print and (-ms-high-contrast: none) {
	.form-select {
		padding-right: 0.75rem
			/* 12px */
		;
	}
}

.form-select:focus {
	outline: none;
	box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
	border-color: #63b3ed;
}

.form-textarea {
	appearance: none;
	background-color: #fff;
	border-color: #e2e8f0;
	border-width: 1px;
	border-radius: 0.25rem
		/* 4px */
	;
	padding-top: 0.5rem
		/* 8px */
	;
	padding-right: 0.75rem
		/* 12px */
	;
	padding-bottom: 0.5rem
		/* 8px */
	;
	padding-left: 0.75rem
		/* 12px */
	;
	font-size: 1rem
		/* 16px */
	;
	line-height: 1.5;
}

.h-85vh {
	max-height: 75vh;
}

/* Scrollbar */
::-webkit-scrollbar {
	width: 8px;
	height: 9px;
}

/* ::-webkit-scrollbar-track {
	opacity: 100;
} */

::-webkit-scrollbar-thumb {
	background: rgb(202, 188, 188);
	border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
	background: rgb(197, 186, 186);
}

/* SWAL STYLING */
.swal2-popup {
	font-size: 12px !important;
	font-family: 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
}